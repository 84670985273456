<template>
  <VI-Scroll-Div class="PlantCompass" ref="scrollDiv">
    <VI-Loader class="loader" :active="loading" />
    <div class="top">
      <div class="sunburst">
        <div :class="[ 'openSunburstOptionsIcon', { 'active': showSunburstOptions } ]" v-on:click.stop.prevent="showSunburstOptions = !showSunburstOptions">
          <VI-Icon type="cog" />
        </div>
        <div class="sunburstOptionsPopup" v-if="showSunburstOptions" v-on:click.stop.prevent >
          <!-- <div class="sunburstDataTypeControl">
            <div>{{edw.sunburstData}}</div>
            <div class="sunburstDataTypeOptionsContainer">
              <div class="sunburstDataTypeOption" v-for="opt in sunburstDataTypeOptions" :key="opt.val" v-on:click="sunburstDataType = opt.val" >
                <div class="label">{{opt.lbl}}</div>
                <VI-Icon class="toggle" :type="sunburstDataType == opt.val ? 'check-circle' : 'circle'" />
              </div>
            </div>
          </div>
          <div class="showAlarmsToggle">
            <div>{{edw.showAlarmsOnSuburst}}</div>
            <VI-Toggle v-model:value="showAlarmsOnSunburst"/>
          </div> -->
          <div class="levelsControl">
            <div class='singleRow'>
              <div class='desc'>{{ edw.sunburstLevels }}:</div>
              <div class="controls">
                <VI-Icon class='modIcon clickableIcon' type='minus-circle' @click='sunburstLevels = Math.max(sunburstLevels-1, 2);'/>
                <div class='val'>{{ sunburstLevels }}</div>
                <VI-Icon class='modIcon clickableIcon' type='plus-circle' @click='sunburstLevels = Math.min(sunburstLevels+1, 5);'/>
              </div>
            </div>
            <div class='singleRow'>
              <div class='desc'>{{ edw.tableLevels }}:</div>
              <div class="controls">
                <VI-Icon class='modIcon clickableIcon' type='minus-circle' @click='tableLevels = Math.max(tableLevels-1, 2);'/>
                <div class='val'>{{ tableLevels }}</div>
                <VI-Icon class='modIcon clickableIcon' type='plus-circle' @click='tableLevels = Math.min(tableLevels+1, 6);'/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <VI-Sunburst-Navigator ref='sunburst' :items='sunburstNodes' :itemsValues="sunburstNodesVals" :itemsColors="sunburstNodesColors" :maxLevels='sunburstLevels' v-model:selected='selectedNodeId' :loading='loadingNodes' fillDunutHole/>
        </div>
      </div>
      <div class='charts'>
        <div class='controls'>
          <!-- <VI-Select :label='edw.presets' :options='visPresetOptions' v-model:value='visPreset'/> -->
          <VI-Rangepicker v-if="chartType != 'windy'" v-model:value="dataInterval" dropdowns opens="left"/>
        </div>
        <div class='mainContainer'>
          <div class='chartContainer'>
            <VI-Windy class="windy" ref="windy" v-if="chartType == 'windy'" :lat="plantLat" :lon="plantLon" :overlay="windyConfig.overlay || 'clouds'" :level="windyConfig.level || 'surface'"/>
            <VI-Chart class="chart" ref="chart" v-if="chartType != 'windy'" title='' subtitle='' :series='chartConf.series' :data='chartConf.data' :options='chartConf.options' :yAxis='chartConf.yAxis' :xAxis='chartConf.xAxis' />
            <VI-Loader :active="chartConf.loadingSeries > 0" />
          </div>
          <div class='typeSel'>
            <VI-Icon :class='[ "chartTypeIcon", { "active": chartType == "line" } ]' @click='setChartType("line")' type='chart-line'/>
            <VI-Icon :class='[ "chartTypeIcon", { "active": chartType == "scatter" } ]' @click='setChartType("scatter")' type='chart-scatter'/>
            <VI-Icon :class='[ "chartTypeIcon", { "active": chartType == "windy" } ]' @click='setChartType("windy")' :type="windyConfig.icon || 'clouds'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="tablesHeader">
      <div class="title">{{edw.tables}}</div>
    </div>
    <div class='tables'>
      <VI-Table v-for="(table, index) in tables" :key="index" :cols="table.columns" v-model:data="table.data" :highlighted="table.highlighted" :old="table.old" :oldColor="oldColor" :title="table.title" :options="table.options" class="dataTable" :style="{ '--border-top-color': 'var(--chart-color-'+((index%20)+1)+')' }"/>
    </div>
  </VI-Scroll-Div>
</template>

<script>
/* global visionConf, HigJS, edw */

import _ from 'lodash';
import { DateTime } from 'luxon';
import VISunburstNavigator from '../components/VI-Sunburst-Navigator.vue';
import VIIcon from '../components/VI-Icon.vue';
import VIRangepicker from '../components/VI-Rangepicker.vue';
import VIChart from '../components/VI-Chart.vue';
import VITable from '../components/VI-Table.vue';
import VIScrollDiv from '../components/VI-Scroll-Div.vue';
import VILoader from '../components/VI-Loader.vue';
import VIWindy from '../components/VI-Windy.vue';
import Utils from '../libs/utils';

export default {
  name: 'PlantCompass',
  components: {
    VIIcon,
    VIChart,
    VITable,
    VIRangepicker,
    VISunburstNavigator,
    VIWindy,
    VIScrollDiv,
    VILoader
  },
  data () {
    const config = (visionConf && visionConf.compass) || {};

    return {
      config: config,
      alarmColors: (visionConf && visionConf.alarmColors) || {},

      loading: true,
      nodes: undefined,

      varPeriods: undefined,
      oldMultiplier: (visionConf.oldMultiplier && visionConf.oldMultiplier.value) || 5,
      oldColor: (visionConf.oldMultiplier && visionConf.oldMultiplier.color) || 'var(--color-error)',

      plantLat: '0',
      plantLon: '0',
      plantZone: '',

      showSunburstOptions: false,

      sunburstLevels: 2,
      sunburstDataTypeOptions: [{ val: 'none', lbl: edw('none') }],
      sunburstDataType: 'none',
      showAlarmsOnSunburst: false,

      selectedNodeId: undefined,

      dataInterval: Utils.interval(config.defaultDataInterval || 'currMonth'),
      visPresetOptions: [{ val: 'custom', lbl: edw('custom') }],
      visPreset: 'custom',
      chartType: 'windy',
      windyConfig: (config && config.windyConfig && typeof config.windyConfig === 'object' && config.windyConfig) || {},

      chartConf: {
        series: [],
        data: {},
        options: {},
        yAxis: {},
        xAxis: {},
        loadingSeries: 0
      },
      chartVarCount: 0,
      chartVars: {},
      chartDataDaemon: undefined,
      chartAxis: {},
      chartColorIndex: 0,

      tablesCreationTo: undefined,
      tables: [],
      tableVarToCell: {},
      tableCellToVar: {},
      selectedCells: {},
      tableLevels: (config && typeof config.tableLevels === 'number' && config.tableLevels) || 2,
      tablesDataDaemon: undefined,

      pageReflowTo: undefined,

      plantId: undefined,
      edw: {
        sunburstLevels: edw('sunburstLevels'),
        tableLevels: edw('tableLevels'),
        sunburstData: edw('sunburstData'),
        presets: edw('presets'),
        dataInterval: edw('dataInterval'),
        tables: edw('tables'),
        showLastData: edw('showLastData'),
        showAlarmsOnSuburst: edw('showAlarmsOnSuburst')
      },
      allNodesAlready: false,
      sunburstNodes: [],
      alreadyLoadedNodes: {},
      pageClosed: false,
      numDec: visionConf.numDec || 2
    };
  },
  computed: {
    plantNode: function () { return this.$store.state.Nodes.nodes[this.plantId]; },
    loadingNodes: function () { return !this.nodes; },
    activeAlarms: function () { return this.$store.state.activeAlarms; },
    sunburstNodesVals: function () {
      const ret = {};

      this.sunburstNodes.forEach((sunNode) => {
        if (typeof ret[sunNode.id] === 'undefined') { ret[sunNode.id] = 1; }
      });

      return ret;
    },
    sunburstNodesColors: function () {
      const ret = new Map();
      let tmpSN;

      this.activeAlarms && this.activeAlarms.forEach((alarm) => {
        if (alarm.levelType === 'nolink') {
          ret.set(String(alarm.nodeId), this.alarmColors.noLink);
        } else if (alarm.levelType === 'alarm') {
          ret.set(String(alarm.nodeId), this.alarmColors.fault);
        } else if (alarm.levelType === 'warning') {
          ret.set(String(alarm.nodeId), this.alarmColors.warning);
        }
      });

      for (const [key, value] of ret) {
        tmpSN = this.sunburstNodes.find((sN) => String(sN.id) === String(key));

        if (tmpSN && tmpSN.parent) {
          if (ret.get(String(tmpSN.parent))) {
            if (ret.get(String(tmpSN.parent)) === this.alarmColors.noLink) {
              ret.set(String(tmpSN.parent), value);
            } else if (ret.get(String(tmpSN.parent)) === this.alarmColors.warning && value === this.alarmColors.fault) {
              ret.set(String(tmpSN.parent), value);
            }
          } else {
            ret.set(String(tmpSN.parent), value);
          }
        }
      }

      this.sunburstNodes.forEach((sunNode) => {
        if (ret.get(String(sunNode.id)) === undefined) { ret.set(String(sunNode.id), this.alarmColors.ok || 'var(--color-highlight)'); }
      });

      const obj = {};
      for (const [key, value] of ret) {
        obj[key] = value;
      }

      return obj;
    }
  },
  watch: {
    $route: {
      // put here initialization of plant relative variables
      // (this is the only function executed when changing between plant pages)
      immediate: true,
      handler: function () {
        if (String(this.plantId) !== String(this.$route.params.plantId)) {
          this.plantId = this.$route.params.plantId;
          this.allNodesAlready = false;
          this.pageClose = false;

          this.getVarPeriods();
          this.createTables();
          this.loadPlantInfo();
        }
      }
    },
    sunburstNodes: {
      immediate: true,
      handler: function () {
        if (!Array.isArray(this.sunburstNodes)) { return; }

        if (this.selectedNodeId == null || this.sunburstNodes.find((node) => { return String(node.id) === String(this.selectedNodeId); }) == null) {
          for (let i = 0; i < this.sunburstNodes.length; i++) { // find a new selected node
            if (!this.sunburstNodes[i].parent) {
              this.selectedNodeId = this.sunburstNodes[i].id;
              break;
            }
          }
        }
      }
    },
    selectedNodeId: {
      immediate: true,
      handler: function () {
        this.createTables();
      }
    },
    plantNode: {
      immediate: true,
      handler: function () {
        this.loadPlantInfo();
        this.createNodesList();
      }
    },
    nodes: {
      handler: function () {
        if (!this.allNodesAlready) {
          this.createTables();
        }
      }
    },
    dataInterval: {
      handler: function () {
        if (this.chartDataDaemon) { this.chartConf.loadingSeries++; this.chartDataDaemon.tickNow(); }
        if (this.tablesDataDaemon) { this.tablesDataDaemon.tickNow(); }
      }
    },
    showSunburstOptions: {
      handler: function () {
        if (this.showSunburstOptions) {
          document.body.addEventListener('click', this.hideSunburstOptCallback);
        } else {
          document.body.removeEventListener('click', this.hideSunburstOptCallback);
        }
      }
    },
    tableLevels: {
      handler: function () {
        this.createTables();
      }
    }
  },
  methods: {
    async getVarPeriods () {
      if (!this.varPeriods) {
        this.varPeriods = await this.$getVarPeriods();
      }
    },
    async createNodesList () {
      if (!this.plantId || !this.$store.state.Nodes.nodes[this.plantId]) { return undefined; }

      const nodesArr = await this.$getNodeChildsOfType(this.plantId);
      nodesArr.unshift(this.$store.state.Nodes.nodes[this.plantId]);
      const allowedTypes = this.config.nodeTypes.reduce((acc, type) => { acc[type] = true; return acc; }, {});
      const correctParent = {};
      while (nodesArr[0]) {
        const curNode = nodesArr.shift();

        if (allowedTypes[curNode.type]) {
          correctParent[curNode.id] = curNode.id;
        } else {
          if (curNode.parentId) {
            if (correctParent[curNode.parentId]) {
              correctParent[curNode.id] = correctParent[curNode.parentId];
            } else {
              nodesArr.push(curNode);
            }
          } else {
            correctParent[curNode.id] = '';
          }
        }
      }

      const tmpRet = await this.$getNodeChildsOfType(this.plantId);
      tmpRet.unshift(this.$store.state.Nodes.nodes[this.plantId]);
      const ret = tmpRet.reduce((acc, curNode) => {
        if (allowedTypes[curNode.type]) {
          acc[curNode.id] = HigJS.obj.clone(curNode);
          acc[curNode.id].parentId = correctParent[curNode.parentId];
        }
        return acc;
      }, {});

      this.nodes = ret;
      this.sunburstNodes.length === 0 && (this.sunburstNodes = this.createSunburstNodes());
    },
    createSunburstNodes () {
      const clonedNodes = HigJS.obj.advClone(this.nodes);
      let cabinCount = 0;
      let cabinId;
      let levelCount = 0;

      for (const node in clonedNodes) {
        if (clonedNodes[node].type === 'cabin') {
          cabinCount++;
          cabinId = node;
        }
      }

      if (cabinCount === 1) {
        for (const node in clonedNodes) {
          if (String(clonedNodes[node].parentId) === String(clonedNodes[cabinId].parentId)) {
            levelCount++;
          }
        }

        if (levelCount === 1) {
          const parentId = clonedNodes[cabinId].parentId;
          delete clonedNodes[cabinId];

          for (const node in clonedNodes) {
            if (String(clonedNodes[node].parentId) === String(cabinId)) {
              clonedNodes[node].parentId = parentId;
            }
          }
        }
      }

      return Object.keys(clonedNodes).map((nodeId) => {
        return {
          id: nodeId,
          name: clonedNodes[nodeId].label,
          parent: clonedNodes[nodeId].parentId
        };
      });
    },
    hideSunburstOptCallback () { // this is for the click listener on body to add and remove it
      this.showSunburstOptions = false;
    },
    async getNodesInfo (reqs) {
      if (!this.plantId || !this.config || !this.config.varsByNodeType) return;
      const variables = [];
      const parameters = [];
      const nodes = [];
      const reqsKeys = Object.keys(reqs);

      for (let i = 0; i < reqsKeys.length; i++) {
        const splitReq = reqsKeys[i].split('-');

        if (this.alreadyLoadedNodes[splitReq[1]]) continue;

        try {
          if (reqs[reqsKeys[i]].type === 'agg') {
            if (!HigJS.obj.customHasOwnProperty({ target: this.$store.state.Nodes.nodes[splitReq[1]].variables, key: Utils.convertVarPeriod(reqs[reqsKeys[i]].period, this.varPeriods) + '.' + reqs[reqsKeys[i]].variable })) throw new Error('err');
          } else {
            if (!HigJS.obj.customHasOwnProperty({ target: this.$store.state.Nodes.nodes[splitReq[1]].parameters, key: reqs[reqsKeys[i]].variable })) throw new Error('err');
          }
        } catch (e) {
          if (reqs[reqsKeys[i]].type === 'agg' && !variables.includes(reqs[reqsKeys[i]].variable)) {
            variables.push(reqs[reqsKeys[i]].variable);
          } else if (!parameters.includes(reqs[reqsKeys[i]].variable)) {
            parameters.push(reqs[reqsKeys[i]].variable);
          }
        }

        if (!nodes.includes(splitReq[1])) nodes.push(splitReq[1]);

        this.alreadyLoadedNodes[splitReq[1]] = true;
      }

      if (nodes.length === 0) return;

      Object.keys(this.config.varsByNodeType).forEach(nodeType => {
        this.config.varsByNodeType[nodeType].forEach(obj => {
          if (obj.type === 'agg' && !variables.includes(obj.variable)) {
            variables.push(obj.variable);
          }
          if (obj.type === 'raw' && !parameters.includes(obj.variable)) parameters.push(obj.variable);
        });
      });

      await this.$getNodesPartially(nodes, { parameters: parameters, variables: variables });
    },
    loadPlantInfo () {
      if (!this.plantNode || !this.plantNode.parameters || !this.plantNode.parameters.plantRefId.val) { return; }

      this.$getPlantInfo(this.plantNode.parameters.plantRefId.val).then((plantInfo) => {
        if (!plantInfo) { return; }

        this.plantLat = plantInfo.lat;
        this.plantLon = plantInfo.lng;
        this.plantZone = plantInfo.timezone;
      }).catch((error) => {
        HigJS.debug.error(error);
      });
    },
    async createTables () {
      // this initial part is used to stop the while loop that was started by a previous create table
      // and that would otherway keep on looping until it had finished that job.
      if (this.currentflags) {
        this.currentflags.stopwhile = true;
      }

      const currentflags = this.currentflags = {
        stopwhile: false
      };

      this.deselectAllVars();
      if (!this.nodes || !this.selectedNodeId || !this.config) { return []; }
      if (this.$store.state.Nodes.childNodesLoaded[this.plantId]) this.allNodesAlready = true;

      clearTimeout(this.tablesCreationTo); // debounce tables creation
      this.tablesCreationTo = setTimeout(async () => {
        if (this.tablesDataDaemon) { this.tablesDataDaemon.stop(); }

        let i;
        const tables = [];
        const tableVarToCell = {};
        const tableCellToVar = [];
        const selNode = this.nodes[this.selectedNodeId];
        let tablesNodes = [];
        const tablesConfigs = [];

        tablesConfigs.push({
          nodeType: selNode.type,
          nodes: [selNode],
          vars: this.config.varsByNodeType[selNode.type] || []
        });
        tablesNodes.push(selNode);

        let prevLvlNodes = { [selNode.id]: true }; let curLvlNodes = {};
        for (let i = 1; i < this.tableLevels; i++) {
          const curLvlChildsByType = Object.keys(this.nodes)
            .filter((nodeId) => { return prevLvlNodes[this.nodes[nodeId].parentId]; })
            .reduce((acc, nodeId) => {
              const curNode = this.nodes[nodeId];
              if (!acc[curNode.type]) { acc[curNode.type] = []; }
              acc[curNode.type].push(curNode);
              curLvlNodes[curNode.id] = true;

              return acc;
            }, {});

          this.config.nodeTypes.forEach((type) => {
            if (curLvlChildsByType[type] && curLvlChildsByType[type].length > 0) {
              tablesConfigs.push({
                nodeType: type,
                nodes: curLvlChildsByType[type],
                vars: this.config.varsByNodeType[type] || []
              });

              tablesNodes = tablesNodes.concat(curLvlChildsByType[type]);
            }
          });

          prevLvlNodes = curLvlNodes;
          curLvlNodes = {};
        }

        for (i = 0; i < tablesConfigs.length; i++) {
          const varToCellRows = {};
          const cellToVarRows = [];
          const table = {
            columns: [],
            data: [],
            requests: {},
            highlighted: {},
            old: {},
            title: {},
            options: {
              pagination: this.config.pagination || 100,
              onCellClick: ((tableI) => { return (row, col) => { this.onCellClick(tableI, row, col); }; })(i),
              onHeadClick: ((tableI) => { return (col) => { this.onHeadClick(tableI, col); }; })(i),
              initialOrder: { name: 'name', direction: 1 }
            }
          };

          table.columns.push({ name: 'name', label: edw(tablesConfigs[i].nodeType), width: '300px', orderable: true });
          tablesConfigs[i].vars.forEach((curVar) => { // adding columns
            let um = '';
            if (curVar.um) {
              um = ' (' + curVar.um + ')';
            }
            table.columns.push({ name: curVar.id, label: edw(curVar.label || curVar.variable) + um, orderable: true });
          });

          tablesConfigs[i].nodes.forEach((curNode, rowI) => {
            const varToCellCols = {};
            const cellToVarCols = {};

            const row = { id: curNode.id, name: curNode.label };

            tablesConfigs[i].vars.forEach((curVar) => {
              varToCellCols[curVar.id] = { col: curVar.id, request: { nodeId: curNode.id, ..._.cloneDeep(curVar) } };
              cellToVarCols[curVar.id] = { varId: curVar.id };
              row[curVar.id] = undefined;
            });
            table.data.push(row);
            varToCellRows[curNode.id] = { row: rowI, cols: varToCellCols };
            cellToVarRows.push({ nodeId: curNode.id, cols: cellToVarCols });
          });

          tables.push(table);
          tableVarToCell[tablesConfigs[i].nodeType] = { table: i, rows: varToCellRows };
          tableCellToVar.push({ nodeType: tablesConfigs[i].nodeType, rows: cellToVarRows });
        }

        this.tables = tables;
        this.tableVarToCell = tableVarToCell;
        this.tableCellToVar = tableCellToVar;

        if (this.config.defaultViewByNodeType && this.config.defaultViewByNodeType[selNode.type]) {
          this.setChartType(this.config.defaultViewByNodeType[selNode.type].type);
          if (Array.isArray(this.config.defaultViewByNodeType[selNode.type].variables)) {
            this.config.defaultViewByNodeType[selNode.type].variables.forEach((chartVar) => {
              tablesNodes.forEach((node) => {
                if (String(node.type) === String(chartVar.nodeType)) {
                  this.selectVar(node.id, chartVar.variableId, true);
                }
              });
            });
          }
        }

        // Add requests of each table to the daemon
        const reqs = Object.keys(this.tableVarToCell).reduce((acc, nodeType) => {
          Object.keys(this.tableVarToCell[nodeType].rows).forEach((nodeId) => {
            Object.keys(this.tableVarToCell[nodeType].rows[nodeId].cols).forEach((varId) => {
              acc[nodeType + '-' + nodeId + '-' + varId] = _.cloneDeep(this.tableVarToCell[nodeType].rows[nodeId].cols[varId].request);

              acc[nodeType + '-' + nodeId + '-' + varId].interval = 'last';
            });
          });
          return acc;
        }, {});

        const reqsKeys = Object.keys(reqs);

        const tmpReqs = {};

        this.tablesDataDaemon = this.$getDataDaemon(
          () => { // requests generation
            return tmpReqs;
          },
          (data) => { // data callback
            this.elaborateAjaxResponse(data);
          },
          Utils.ms('15m') // interval,
        );

        this.loading && (this.loading = false);

        while (reqsKeys.length && !this.pageClosed && !currentflags.stopwhile) {
          const tmpNodes = [];
          const partialReqs = {};
          for (let i = 0; i < 20; i++) {
            if (reqsKeys[i]) {
              tmpReqs[reqsKeys[i]] = HigJS.obj.advClone(reqs[reqsKeys[i]]);
              partialReqs[reqsKeys[i]] = HigJS.obj.advClone(reqs[reqsKeys[i]]);
              if (!tmpNodes.includes(reqs[reqsKeys[i]].nodeId)) tmpNodes.push(reqs[reqsKeys[i]].nodeId);
            }
          }

          reqsKeys.splice(0, 20);

          await this.getNodesInfo(partialReqs);

          await this.$getData(partialReqs).then(async (data) => {
            await this.elaborateAjaxResponse(data);
          });
        }
      }, 500);
    },
    async elaborateAjaxResponse (response) {
      let path, cellData, request;

      Object.keys(response).forEach(async (data) => {
        path = data.split('-');
        path.push('request');
        path.splice(1, 0, 'rows');
        path.splice(3, 0, 'cols');
        cellData = undefined;
        request = HigJS.obj.getObjDeep(this.tableVarToCell, path.join('.'));

        if (request && response[data]) {
          if (Array.isArray(response[data][0])) {
            cellData = response[data][0][1];
            this.checkOld(response[data][0][0], request.type, request.period, path[2], path[4]);
          }
        }

        cellData = HigJS.num.format(Utils.round(cellData, request.nDec || this.numDec));
        this.tables[this.tableVarToCell[path[0]].table].data[this.tableVarToCell[path[0]].rows[path[2]].row][this.tableVarToCell[path[0]].rows[path[2]].cols[path[4]].col] = cellData;
      });
    },
    onCellClick (tableI, row, col) {
      if (col === 'name') {
        // get the number of columns (minus the 'name' one) and the number of selected cells in the current row

        const totColsN = Object.keys(this.tableCellToVar[tableI].rows[row].cols).length;
        const selColsN = Object.keys(this.tableCellToVar[tableI].rows[row].cols).reduce((acc, curCol) => {
          acc += (this.tables[tableI].highlighted[row] && this.tables[tableI].highlighted[row][curCol]) ? 1 : 0;
          return acc;
        }, 0);

        Object.keys(this.tableCellToVar[tableI].rows[row].cols).forEach((curCol) => { // select all columns on the clicked row
          if (curCol === 'name') { return; }
          this.selectVar(
            this.tableCellToVar[tableI].rows[row].nodeId,
            this.tableCellToVar[tableI].rows[row].cols[curCol].varId,
            selColsN < totColsN / 2); // force select if the minority of the cells in the current row are selected and vice versa
        });
      } else {
        this.selectVar(
          this.tableCellToVar[tableI].rows[row].nodeId,
          this.tableCellToVar[tableI].rows[row].cols[col].varId);
      }
    },
    onHeadClick (tableI, col) {
      if (col === 'name') { return; }

      // get the number of rows and the number of selected cells in the current column
      const towRowsN = this.tableCellToVar[tableI].rows.length;
      const selRowsN = this.tableCellToVar[tableI].rows.reduce((acc, rowIndex, row) => {
        acc += (this.tables[tableI].highlighted[row] && this.tables[tableI].highlighted[row][col]) ? 1 : 0;
        return acc;
      }, 0);

      this.tableCellToVar[tableI].rows.forEach((rowIndex, row) => {
        this.selectVar(
          this.tableCellToVar[tableI].rows[row].nodeId,
          this.tableCellToVar[tableI].rows[row].cols[col].varId,
          selRowsN < towRowsN / 2); // force select if the minority of the cells in the current col are selected and vice versa
      });
    },
    addGraphSeries (id, request) { // if true select it on the table, else do not
      if (!this.nodes[request.nodeId]) { return false; }

      const localNumDec = this.numDec;
      let itemUm = '';
      if (request.type === 'agg') {
        try {
          const periods = Object.keys(this.nodes[request.nodeId].variables);
          request.period = Utils.convertVarPeriod(request.period, periods);

          itemUm = this.nodes[request.nodeId].variables[request.period][request.variable].um;
        } catch (err) { return false; }
      } else if (request.type === 'raw') {
        try {
          itemUm = this.nodes[request.nodeId].parameters[request.variable].um;
        } catch (err) { return request; }
      } else {
        return false;
      }

      if (this.chartType === 'line') {
        if (itemUm && !this.chartConf.yAxis[itemUm]) {
          this.chartConf.yAxis[itemUm] = {
            labels: { formatter: function () { return HigJS.num.format(Utils.round(this.value, localNumDec)); } },
            title: { text: itemUm },
            opposite: (this.chartConf.yAxis.length > 0)
          };

          const splitId = id.split('-');
          if (splitId.length >= 3 && this.config.varsByNodeType[splitId[0]]) {
            const conf = this.config.varsByNodeType[splitId[0]].find(el => String(el.id) === String(splitId[2]));
            if (conf && conf.min != null && !Number.isNaN(Number(conf.min))) this.chartConf.yAxis[itemUm].min = Number(conf.min);
          }
        }

        this.chartVars[id] = _.cloneDeep(request);
        this.chartConf.series.push({
          id: id,
          name: this.nodes[request.nodeId].label + ' - ' + edw(request.label),
          color: 'var(--chart-color-' + ((this.chartColorIndex % 20) + 1) + ')',
          yAxis: itemUm || undefined,
          marker: { enabled: false },
          tooltip: { valueDecimals: 2 }
        });
        this.chartColorIndex = this.chartColorIndex + 2;

        this.chartVars[id].interval = _.cloneDeep(this.dataInterval);

        this.chartConf.loadingSeries++;

        this.$getData({ [id]: this.chartVars[id] }).then((data) => {
          Object.keys(data).forEach((dataId) => {
            this.chartConf.data[dataId] = data[dataId] || [];

            if (this.chartVars[id].type === 'raw' && data[dataId].length > 0) {
              const lastIndex = data[dataId].length - 1;
              const interval = data[dataId][1][0] ? data[dataId][1][0] - data[dataId][0][0] : 3000;
              const currentDate = new Date(data[dataId][lastIndex][0] + interval);
              const offset = currentDate.getTimezoneOffset() * 60000;
              let time = currentDate.getTime();
              const endDate = new Date(currentDate.getTime() + offset);
              endDate.setHours(23, 59, 59);
              while (time <= endDate.getTime()) {
                data[dataId].push([time, null]);
                time += interval;
              }
            }
          });
          this.chartConf.loadingSeries--;
        });

        this.chartVarCount++;
        // console.log(this.chartConf);
        return true;
      } else if (this.chartType === 'scatter') {
        if (Object.keys(this.chartVars).find((chartVarId) => { return this.chartVars[chartVarId].axis === 'x'; }) == null) { // x is missing
          this.chartVars[id] = _.cloneDeep(request);
          this.chartVars[id].axis = 'x';
          this.chartConf.xAxis.x = { labels: { formatter: function () { return HigJS.num.format(Utils.round(this.value, localNumDec)); } }, title: { text: this.nodes[request.nodeId].label + ' - ' + edw(this.chartVars[id].label) + (itemUm ? ' (' + itemUm + ')' : '') }, type: 'linear' };
        } else if (Object.keys(this.chartVars).find((chartVarId) => { return this.chartVars[chartVarId].axis === 'y'; }) == null) { // y is missing
          this.chartVars[id] = _.cloneDeep(request);
          this.chartVars[id].axis = 'y';
          this.chartConf.yAxis.y = { labels: { formatter: function () { return HigJS.num.format(Utils.round(this.value, localNumDec)); } }, title: { text: this.nodes[request.nodeId].label + ' - ' + edw(this.chartVars[id].label) + (itemUm ? ' (' + itemUm + ')' : '') }, type: 'linear' };
        } else {
          return false;
        }

        this.chartConf.loadingSeries++;
        if (this.chartVarCount === 0) {
          this.chartVarCount++;
          return true;
        } else if (this.chartVarCount === 1) {
          this.chartConf.series.push({
            id: 'scatter',
            name: 'scatter',
            color: 'var(--chart-color-' + ((this.chartColorIndex % 20) + 1) + ')',
            // lineWidth: 0,
            // states: { hover: { enabled: false, lineWidth: 0 } },
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            }
          });
          this.chartColorIndex = this.chartColorIndex + 2;

          this.chartDataDaemon.tickNow();

          this.chartVarCount++;
          return true;
        }

        return false;
      }

      return false;
    },
    remGraphSeries (id) {
      if (!this.chartVars[id]) { return; }

      if (this.chartType === 'line') {
        delete this.chartVars[id];

        let delSeriesYAxis;
        this.chartConf.series = this.chartConf.series.filter((series) => { if (String(series.id) === String(id)) { delSeriesYAxis = series.yAxis; return false; } return true; });

        if (delSeriesYAxis != null) {
          let yAxisStillInUse = false;
          this.chartConf.series.forEach((series) => { if (series.yAxis === delSeriesYAxis) { yAxisStillInUse = true; } });

          if (!yAxisStillInUse) { delete this.chartConf.yAxis[delSeriesYAxis]; }
        }
      } else if (this.chartType === 'scatter') {
        this.chartConf.series = [];
        this.chartConf.data = {};

        if (this.chartVars[id].axis === 'x') {
          delete this.chartConf.xAxis.x;
        } else if (this.chartVars[id].axis === 'y') {
          delete this.chartConf.yAxis.y;
        }

        delete this.chartVars[id];
      }

      this.chartVarCount--;
    },
    setChartType (newType) {
      if (String(newType) === String(this.chartType)) { return; }
      const localNumDec = this.numDec;
      if (this.chartDataDaemon) {
        this.chartDataDaemon.stop();
        this.chartDataDaemon = undefined;
      }

      this.deselectAllVars();

      if (newType === 'line') {
        this.chartConf.options.chart = { type: 'line' };
        this.chartConf.options.legend = { enabled: true };
        this.chartConf.options.tooltip = {
          formatter: function () {
            const tooltips = this.points.map(function (point) {
              return point.series.name + ': ' + HigJS.num.format(Utils.round(point.y, localNumDec));
            });

            tooltips.unshift(HigJS.num.formatDate({ date: true, time: true, utc: this.points[0].x / 1000 }));
            return tooltips;
          },
          split: true
        };

        this.chartDataDaemon = this.$getDataDaemon(
          () => {
            for (const reqId in this.chartVars) { this.chartVars[reqId].interval = _.cloneDeep(this.dataInterval); }
            return this.chartVars;
          }, (data) => {
            Object.keys(data).forEach((dataId) => { this.chartConf.data[dataId] = data[dataId] || []; });
            this.chartConf.loadingSeries = 0;
          },
          Utils.ms('15m')
        );
      } else if (newType === 'scatter') {
        this.chartConf.options.chart = { type: 'scatter' };
        this.chartConf.options.legend = { enabled: false };
        this.chartConf.options.tooltip = {
          formatter: function () {
            return 'x: <b>' + HigJS.num.format(Utils.round(this.point.x, localNumDec)) + '</b><br/>y: <b>' + HigJS.num.format(Utils.round(this.point.y, localNumDec)) + '</b><br/>';
          },
          split: false
        };

        this.chartDataDaemon = this.$getDataDaemon(
          () => {
            const xVarId = Object.keys(this.chartVars).find((chartVarId) => { return this.chartVars[chartVarId].axis === 'x'; });
            const yVarId = Object.keys(this.chartVars).find((chartVarId) => { return this.chartVars[chartVarId].axis === 'y'; });
            if (!xVarId || !yVarId) {
              this.chartConf.loadingSeries = 0;
              return undefined; // if requests are undefined the callback will not be executed
            }

            this.chartVars[xVarId].interval = this.chartVars[yVarId].interval = _.cloneDeep(this.dataInterval);
            return { [xVarId]: this.chartVars[xVarId], [yVarId]: this.chartVars[yVarId] };
          }, (data) => {
            const xVarId = Object.keys(this.chartVars).find((chartVarId) => { return this.chartVars[chartVarId].axis === 'x'; });
            const yVarId = Object.keys(this.chartVars).find((chartVarId) => { return this.chartVars[chartVarId].axis === 'y'; });
            if (!data[xVarId] || !data[yVarId]) { return; }

            let xI = 0; let yI = 0; const scatterData = [];

            while (xI < data[xVarId].length && yI < data[yVarId].length) {
              if (!data[xVarId][xI] ||
                  !data[xVarId][xI][0] ||
                  !data[yVarId][yI] ||
                  !data[yVarId][yI][0]) { return; }

              if (Number(data[xVarId][xI][0]) === Number(data[yVarId][yI][0])) {
                scatterData.push([data[xVarId][xI][1], Utils.round(data[yVarId][yI][1])]);

                xI++; yI++;
              } else if (data[xVarId][xI][0] < data[yVarId][yI][0]) { xI++; } else if (data[xVarId][xI][0] > data[yVarId][yI][0]) { yI++; }
            }

            this.chartConf.data.scatter = scatterData;
            this.chartConf.loadingSeries = 0;
          },
          Utils.ms('15m')
        );
      }

      this.chartType = newType;
    },
    selectVar (nodeId, varConfId, forcedState) {
      if (!this.tables || !this.tableVarToCell) { return; }

      let tableI, row, col;
      const nodeType = this.nodes[nodeId].type;

      try {
        tableI = this.tableVarToCell[nodeType].table;
        row = this.tableVarToCell[nodeType].rows[nodeId].row;
        col = this.tableVarToCell[nodeType].rows[nodeId].cols[varConfId].col;
      } catch (e) { return; }

      if (tableI == null || row == null || col == null) return;

      if (!this.tables[tableI].highlighted[row]) { this.tables[tableI].highlighted[row] = {}; }

      const selectedFrom = !!this.tables[tableI].highlighted[row][col];
      const selectedTo = forcedState == null ? !selectedFrom : !!forcedState; // if forcedState is undefined invert the state, instead use the value in selected

      if (selectedFrom === selectedTo) { return; }

      if (selectedTo) {
        if (this.addGraphSeries(nodeType + '-' + nodeId + '-' + varConfId, this.tableVarToCell[nodeType].rows[nodeId].cols[varConfId].request)) {
          this.tables[tableI].highlighted[row][col] = true;
        }
      } else {
        this.remGraphSeries(nodeType + '-' + nodeId + '-' + varConfId);
        this.tables[tableI].highlighted[row][col] = false;
      }
    },
    deselectAllVars () {
      this.tables.forEach((table) => { table.highlighted = {}; });
      this.resetChart();
    },
    resetChart () {
      this.chartVars = {};
      this.chartVarCount = 0;
      this.chartConf.series = [];
      this.chartConf.data = {};
      this.chartConf.options = {};
      this.chartConf.yAxis = {};
      this.chartConf.xAxis = {};
    },
    reflowPage () {
      clearTimeout(this.pageReflowTo);
      this.pageReflowTo = setTimeout(() => {
        if (this.$refs.sunburst) { this.$refs.sunburst.reflow(); }
        if (this.$refs.chart) { this.$refs.chart.reflow(); }
        if (this.$refs.windy) { this.$refs.windy.reflow(); }
      }, 200);
    },
    checkOld (utc, type, period, nodeId, varConfId, varType) {
      if (!this.varPeriods || !utc) return;
      let fullPeriod;

      let checkedPeriod;

      if (type === 'agg') {
        checkedPeriod = Utils.convertVarPeriod(period, this.varPeriods);
        this.varPeriods.forEach(p => {
          if (p.name === checkedPeriod) fullPeriod = p;
        });
      } else if (type === 'raw') {
        fullPeriod = {};
        fullPeriod.sampleTime = period < 60 ? 60 : parseInt(period);
      }

      if (!fullPeriod) return;

      const correctUtc = this.utcCorrected(utc, this.plantZone);

      const nodeType = this.nodes[nodeId].type;

      const tableI = this.tableVarToCell[nodeType].table;
      const row = this.tableVarToCell[nodeType].rows[nodeId].row;
      const col = this.tableVarToCell[nodeType].rows[nodeId].cols[varConfId].col;

      if (!this.tables[tableI].old[row]) { this.tables[tableI].old[row] = {}; }
      this.tables[tableI].old[row][col] = Utils.checkUtcOld(correctUtc, fullPeriod, this.oldMultiplier);

      if (!this.tables[tableI].title[row]) { this.tables[tableI].title[row] = {}; }
      this.tables[tableI].title[row][col] = HigJS.num.formatDate({ utc: utc / 1000, date: true, time: true });
    },
    utcCorrected (utc, zone) {
      if (!zone) return utc;
      let correct = DateTime.fromMillis(utc).toUTC().toFormat('yyyy LLL dd HH mm ss');
      correct = DateTime.fromFormat(correct, 'yyyy LLL dd HH mm ss', { zone: zone }).toMillis();

      return correct;
    }
  },
  mounted () {
    this.setChartType(this.chartType);
    window.addEventListener('resize', this.reflowPage);
  },
  unmounted () {
    this.pageClosed = true;
    if (this.tablesDataDaemon) { this.tablesDataDaemon.stop(); }
    document.body.removeEventListener('resize', this.reflowPage);
  }
};
</script>

<style scoped>
.PlantCompass{
  display: flex;
  flex-flow: column nowrap;
}

.PlantCompass > .top{
  display: flex;
  flex-flow: row nowrap;
}

.PlantCompass > .top > .sunburst{
  flex: 0 0 50vh;
  min-width: 450px;
  padding: 10px;
  position: relative;
}

.PlantCompass > .top > .sunburst > .openSunburstOptionsIcon{
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
  cursor: pointer;
  transition: transform .3s ease;
  padding: 7px;
  z-index: 2;
}

.PlantCompass > .top > .sunburst > .openSunburstOptionsIcon:hover{
  color: var(--color-highlight);
}

.PlantCompass > .top > .sunburst > .openSunburstOptionsIcon.active{
  color: var(--color-highlight);
  transform: rotate(90deg);
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup{
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 270px;
  background: var(--color-primary-1);
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  border: 2px solid var(--color-highlight);
  padding: 5px 10px 5px 50px;

  display: flex;
  flex-flow: column nowrap;
  border-radius: 4px;

  z-index: 1;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > *:not(:last-child){
  border-bottom: 1px solid var(--color-primary-2);
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .sunburstDataTypeControl{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .sunburstDataTypeControl > .sunburstDataTypeOptionsContainer{
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .sunburstDataTypeControl > .sunburstDataTypeOptionsContainer > .sunburstDataTypeOption{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding: 2px 0px;
  cursor: pointer;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .sunburstDataTypeControl > .sunburstDataTypeOptionsContainer > .sunburstDataTypeOption > .label{}
.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .sunburstDataTypeControl > .sunburstDataTypeOptionsContainer > .sunburstDataTypeOption > .toggle{ font-size: 1.3em; }

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .sunburstDataTypeControl > .sunburstDataTypeOptionsContainer > .sunburstDataTypeOption:hover{ color: var(--color-highlight); }

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .showAlarmsToggle{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .levelsControl{
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .levelsControl > .singleRow{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .levelsControl > .singleRow > .desc{
  margin-right: 4px;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .levelsControl > .singleRow > .controls{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .levelsControl > .singleRow > .controls > .modIcon{ font-size: 1.3em; }
.PlantCompass > .top > .sunburst > .sunburstOptionsPopup > .levelsControl > .singleRow > .controls > .val{ width: 30px; text-align: center; }

.PlantCompass > .top > .charts{
  flex: 0 0 auto;
  width: calc(100% - max(450px, 50vh));
  display: flex;
  flex-flow: column nowrap;

  padding: 10px;
}

.PlantCompass > .top > .charts > .controls{
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  z-index: 1;
}

.PlantCompass > .top > .charts > .mainContainer{
  flex: 0 1 100%;
  display: flex;
  flex-flow: row nowrap;
}

.PlantCompass > .top > .charts > .mainContainer > .chartContainer{
  flex: 0 1 100%;
  padding: 0 10px 0 0;
  position: relative;
  overflow: hidden;
}

.PlantCompass > .top > .charts > .mainContainer > .chartContainer > ::v-deep(.chart){
  margin-top: 35px;
  height: calc(100% - 35px);
}

.PlantCompass > .top > .charts > .mainContainer > .typeSel{
  flex: 0 0 auto;
  margin-top: 45px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}

.PlantCompass > .top > .charts > .mainContainer > .typeSel > .chartTypeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;

  font-size: 2.5em;
  cursor: pointer;
  border-radius: 50%;
}

.PlantCompass > .top > .charts > .mainContainer > .typeSel > .chartTypeIcon:hover{
  color: var(--color-highlight);
}

.PlantCompass > .top > .charts > .mainContainer > .typeSel > .chartTypeIcon.active{
  color: var(--color-highlight-text);
  background: var(--color-highlight);
}

.PlantCompass > .top > .charts > .mainContainer > .typeSel > .chartTypeIcon.active:hover{
  color: var(--color-highlight-text);
}

.PlantCompass > .tablesHeader{
  flex: 0 0 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  background: var(--color-primary-1);
  color: var(--color-primary-text);
  z-index: 1;
}

.PlantCompass > .tablesHeader > .title{
  font-size: 1.3em;
}

.PlantCompass > .tablesHeader > .lastDataToggle{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.PlantCompass > .tablesHeader > .lastDataToggle > .toggle{
  margin-left: 5px;
  --bg-color: var(--color-primary-text);
}

.PlantCompass > .tables ::v-deep(.VITable){
  height: auto;
}

.PlantCompass > .tables > .dataTable{
  border-top: 1px solid var(--border-top-color);
}

.clickableIcon{
  cursor: pointer;
}

.clickableIcon:hover{
  color: var(--color-highlight);
}
</style>
