<template>
  <div class='Frame'>
    <VI-AlarmsList v-if="popups[ popupLevelType ]" :levelType="popupLevelType" :level="popupLevel" :nodeIds="[plantNode.id]" :impiantiId="impiantiId" @close="toggleListPopup" :label="popupListTitle" :onlyActive="true"/>
    <div class='PlantSidebar'>
      <button v-if="backButtonConfig.visible" class="sidebarBackButton" @click="routeBackToList()"><VI-Icon type="arrow-left"/>{{' ' + edw.plantList}}</button>

      <VI-SideBar class="sidebar"
        :name="plantName"
        :potNom="+potNom"
        :totalPower="+power"
        :totalPowerUtc="totalPowerUtc"
        :um="um"
        :status="config.status"
        :plantNoLink="plantNoLink"
        :alarmsList="alarmsType"
        :alarmsSoundActive="alarmsSoundActive"
        :infosList="plantSidebarInfo"
        :loading="cumulativeLoading"
        :buttonsList="reports"
        :timezone="plantInfo ? plantInfo.timezone : ''"
        :gridStatus="gridStatusClass"
        :delayedColor="oldMultiplier && oldMultiplier.color"
        @openAlarmsList="toggleListPopup"
      />
      <VI-Legend v-if="translatedLegend && translatedLegend.elements && translatedLegend.elements.length > 0" :title="translatedLegend.title" :elements="translatedLegend.elements" :border="translatedLegend.border"/>
    </div>
    <div class='PlantPage'>
      <div class='PlantTabs'>
        <VI-Tabs :links='tabsLinks' :basePath='$route.path' class='tabs'/>
      </div>
      <router-view class='PlantNavigator'/>
    </div>
  </div>
</template>

<script>
/* global visionConf, HigJS, edw */

import VITabs from '../components/VI-Tabs.vue';
import VIIcon from '../components/VI-Icon.vue';
import VISideBar from '../components/VI-SideBar.vue';
import VIAlarmsList from '../components/VI-AlarmsList/VI-AlarmsList.vue';
import VILegend from '../components/VI-Legend.vue';
import Utils from '../libs/utils';

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Frame',
  components: {
    VITabs,
    VISideBar,
    VIIcon,
    VIAlarmsList,
    VILegend
  },
  data () { // data will NOT be recalculated when switching from Plant to Plant
    return {
      config: (visionConf && visionConf.frame) || {},
      plantDashboardConfig: {},

      plantInfo: undefined,
      legendConf: (visionConf && visionConf.frame && visionConf.frame.legend) || undefined,
      oldMultiplier: (visionConf && visionConf.oldMultiplier) || {},
      backButtonConfig: (visionConf && visionConf.frame && visionConf.frame.backButton) || { visible: true },
      totalPowerIntervalUpdate: (visionConf && visionConf.frame && visionConf.frame.totalPowerIntervalUpdate) || '5m',

      tabsLinks: [],
      plantId: undefined, // initialized by the $route watcher
      utlTags: '',

      dataLoadDaemon: undefined,

      totalPower: 0,
      totalPowerUtc: 0,
      potNom: undefined,
      um: '',
      power: undefined,
      gridStatusClass: undefined,

      dataLoading: true,
      alarmsLoadDaemon: undefined,

      edw: {
        plantList: edw('plantsList')
      },

      popups: {
        alarm: false,
        warning: false,
        nolink: false
      },

      alarmsType: (visionConf && visionConf.frame && visionConf.frame.alarms) || [],
      alarmsSoundActive: (visionConf && visionConf.frame && visionConf.frame.alarmsSoundActive) || false,

      count: 0,

      popupLevelType: undefined,
      popupLevel: undefined,
      popupListTitle: '',

      listUM: ['pW', 'nW', 'µW', 'mW', 'W', 'kW', 'MW', 'GW', 'TW'],

      alreadySetUp: false,
      alarmsLoading: true,
      numDec: visionConf.numDec || 2
    };
  },
  computed: {
    plantNode: function () { return this.$store.state.Nodes.nodes[this.plantId]; },
    nodeLoading: function () { return !(this.plantNode && this.plantInfo && !this.$store.state.Nodes.loading); },
    activeAlarms: function () { return this.$store.state.activeAlarms; },

    // ### Plant info data
    plantNoLink: function () { return !!this.activeAlarms.find((alarm) => { return alarm.levelType === 'alarm' && alarm.level === 'CONNES' && String(alarm.nodeId) === String(this.plantNode.id); }); },
    plantName: function () { return this.plantNode ? (this.plantNode.label || '---') : '---'; },
    plantDesc: function () { return this.plantInfo ? (this.plantInfo.descrizione || '') : ''; },
    plantSidebarInfo: function () {
      const ret = [];

      if (Array.isArray(this.config.info)) {
        this.config.info.forEach((curInfo) => {
          let val = '---';
          if (curInfo.source === 'info' && this.plantInfo) {
            val = this.plantInfo[curInfo.field] || val;
          } else if (curInfo.source === 'parameter' && this.plantNode && this.plantNode.parameters) {
            val = (this.plantNode.parameters[curInfo.parameter] && this.plantNode.parameters[curInfo.parameter].val) || val;
          }

          ret.push({
            label: edw(curInfo.label),
            value: val
          });
        });
      }

      return ret;
    },
    plantPotNom: function () { return this.plantNode?.parameters?.dcRatedPower?.val || 0; },
    impiantiId: function () {
      return HigJS.obj.getObjDeep(this.plantNode, 'parameters.plantRefId.val');
    },
    reports: function () { return this.config.reports; },
    umPower: function () { return this.config?.variables?.power?.um || 'kW'; },
    translatedLegend: function () {
      if (!this.legendConf) return undefined;
      const translated = {};
      translated.title = edw(this.legendConf.title);
      translated.border = this.legendConf.border;

      if (!Array.isArray(this.legendConf.elements)) {
        translated.elements = [];
      } else {
        this.legendConf.elements.forEach(element => {
          element.text = edw(element.text);
        });
        translated.elements = this.legendConf.elements;
      }
      return translated;
    },
    cumulativeLoading: function () {
      if (!this.dataLoading && !this.alarmsLoading) return false;
      return true;
    }
  },
  watch: {
    $route: {
      // put here initialization of plant relative variables
      // (this is the only function executed when changing between plant pages)
      immediate: true,
      handler: function () {
        if (String(this.plantId) !== String(this.$route.params.plantId)) {
          this.plantId = this.$route.params.plantId;
          if (!this.$store.state.Nodes.nodes[this.plantId]) {
            Promise.all([this.$getNodesFull([this.plantId]), this.$getNodes([this.plantId], true)]);
          }

          this.alreadySetUp = false;
          this.plantInfo = undefined;
          this.dataLoading = true;
          this.alarmsLoading = true;
          this.totalPower = 0;
          this.totalPowerUtc = 0;
        }
      }
    },
    plantNode: async function () {
      if (!this.plantNode || !this.plantNode.parameters || this.alreadySetUp) return;
      if (this.dataLoadDaemon) { this.dataLoadDaemon.stop(); }

      this.alreadySetUp = true;
      await this.loadPlantDashboardConfig();
      this.setupTabs();

      const reqs = {};

      if (this.config.variables.gridStatus && this.config.variables.gridStatus.variable) {
        reqs.gridStatus = {
          nodeId: this.plantNode.id,
          type: this.config.variables.gridStatus.type || 'raw',
          variable: this.config.variables.gridStatus.variable,
          period: this.config.variables.gridStatus.period || 1,
          interval: 'last'
        };
      }

      if (this.config.variables.power && this.config.variables.power.variable) {
        reqs.power = {
          nodeId: this.plantNode.id,
          type: this.config.variables.power.type || 'raw',
          variable: this.config.variables.power.variable,
          period: this.config.variables.power.period || 1,
          interval: 'last'
        };
      }

      this.dataLoadDaemon = this.$getDataDaemon(reqs, (data) => {
        if (Array.isArray(data.gridStatus) && Array.isArray(data.gridStatus[0])) {
          const gridStatusVal = Number(data.gridStatus[0][1]);

          if (this.config.variables.gridStatus.states) {
            if (Array.isArray(this.config.variables.gridStatus.states.ok) && this.config.variables.gridStatus.states.ok.find((el) => { return Number(el) === gridStatusVal; }) != null) {
              this.gridStatusClass = 'ok';
            } else if (Array.isArray(this.config.variables.gridStatus.states.warning) && this.config.variables.gridStatus.states.warning.find((el) => { return Number(el) === gridStatusVal; }) != null) {
              this.gridStatusClass = 'warning';
            } else if (Array.isArray(this.config.variables.gridStatus.states.error) && this.config.variables.gridStatus.states.error.find((el) => { return Number(el) === gridStatusVal; }) != null) {
              this.gridStatusClass = 'error';
            }
          }// get the status from the value of this variable
        }

        if (Array.isArray(data.power) && Array.isArray(data.power[0])) {
          this.totalPower = parseFloat(data.power[0][1]) || 0;
          this.totalPowerUtc = data.power[0][0] / 1000 || 0;
        }
      }, Utils.ms(this.totalPowerIntervalUpdate));

      if (this.plantNode.parameters && this.plantNode.parameters.plantRefId) {
        this.$getPlantInfo(this.plantNode.parameters.plantRefId.val)
          .then((plantInfo) => { this.plantInfo = plantInfo; })
          .catch((error) => { HigJS.debug.error(error); });
      }

      if (this.alarmsLoadDaemon) { this.alarmsLoadDaemon.tickNow(); }
    },
    totalPower: function () {
      this.controlPotNom();
    },
    plantPotNom: function () {
      this.controlPotNom();
    },
    umPower: function () {
      this.controlPotNom();
    },
    activeAlarms: function () {
      if (!this.config.alarms ||
        !Array.isArray(this.config.alarms) ||
        !this.activeAlarms ||
        !Array.isArray(this.activeAlarms)) return;

      let alarmsList;

      // count occurrencies of each configured alarm. to avoid counting the ones with a specific confAlarm.level twice, they are removed after being counted.
      this.config.alarms.forEach((confAlarm) => {
        alarmsList = this.activeAlarms.filter(alarm => String(alarm.levelType) === String(confAlarm.levelType));

        if (confAlarm.level[0] !== 'all') {
          const removeArray = confAlarm.level.filter(el => el.startsWith('!'));
          const filterArray = confAlarm.level.filter(el => !el.startsWith('!'));

          for (const str of removeArray) {
            for (let i = alarmsList.length - 1; i >= 0; i--) {
              if (String(alarmsList[i].level) === str.substring(1)) alarmsList.splice(i, 1);
            }
          }

          if (filterArray.length) {
            for (let i = alarmsList.length - 1; i >= 0; i--) {
              if (!filterArray.includes(alarmsList[i].level)) alarmsList.splice(i, 1);
            }
          }
        }

        // control if the alarm was already in the list and if so update it.
        const index = this.alarmsType.findIndex((alarm) => String(alarm.id) === String(confAlarm.id));
        confAlarm.count = alarmsList.length.toString();
        if (index >= 0) {
          this.alarmsType.splice(index, 1, confAlarm);
        } else {
          this.alarmsType.push(confAlarm);
        }
      });
      this.alarmsLoading && (this.alarmsLoading = false);
    }
  },
  methods: {
    async setupTabs () {
      this.tabsLinks = [];

      if (Array.isArray(this.config.tabs)) {
        for (let i = 0; i < this.config.tabs.length; i++) {
          const tab = this.config.tabs[i];

          // show the new trackers tab only if the configuration is complete
          if (String(tab.type).toLowerCase() === 'tracker' && visionConf.tracker) {
            const trackerConf = visionConf.tracker;

            if (
              (trackerConf.path || trackerConf.parentNode) &&
              (Array.isArray(trackerConf.trackersVars) && trackerConf.trackersVars.length)
            ) {
              tab.type = 'trackerNew';
            }
          }

          if (tab.type === 'external') {
            let url = tab.url;
            if (typeof tab.url === 'function') {
              if (this.plantNode) {
                url = await tab.url.call(this, HigJS.obj.clone(this.plantNode));
              } else {
                url = undefined;
              }
            }
            if (url) {
              this.tabsLinks.push({ label: edw(tab.label), to: { name: 'external', params: { url: url } } });
            }
          } else if (typeof tab.show === 'function') {
            let show = tab.show;
            if (this.plantNode) {
              show = await tab.show.call(this, HigJS.obj.clone(this.plantNode));
            } else {
              show = false;
            }
            if (show) {
              this.tabsLinks.push({ label: edw(tab.label), to: { name: tab.type, query: tab.query, params: tab.params } });
            }
          } else {
            this.tabsLinks.push({ label: edw(tab.label), to: { name: tab.type, query: tab.query, params: tab.params } });
          }
        }
      }
      if (this.plantDashboardConfig && this.plantDashboardConfig?.plants?.[this.plantId]?.dashboard) {
        const dashboardRoute = { label: edw('dashboard'), to: { name: 'external', query: undefined, params: { url: `/interfaces/plugins/vision-plant-dashboard/view.html?#/visionDashboard/${this.plantId}` } } };
        if (this.plantDashboardConfig.plants[this.plantId].asDefault) {
          this.tabsLinks.unshift(dashboardRoute);
          this.$router.push(this.tabsLinks[0].to);
        } else {
          this.tabsLinks.push(dashboardRoute);
        }
      }
    },
    async loadPlantDashboardConfig () {
      this.plantDashboardConfig = await HigJS.cfg.loadConfigs([{ pageId: 'pl_VisionPlantDashboard', nodeId: this.plantNode.id, nodeTypeId: this.plantNode.nodeTypeId }]);
    },
    routeBackToList () {
      let url = '';
      if (this.backButtonConfig.link) {
        if (typeof this.backButtonConfig.link === 'string') {
          url = this.backButtonConfig.link;
        } else if (typeof this.backButtonConfig.link === 'function') {
          url = this.backButtonConfig.link.call(this, this.urlTags);
        }
        if (url) {
          window.location = url;
        }
      } else if (this.config.links) {
        if (this.config.links.plantsList) {
          if (typeof this.config.links.plantsList === 'string') {
            url = this.config.links.plantsList;
          } else if (typeof this.config.links.plantsList === 'function') {
            url = this.config.links.plantsList.call(this, this.urlTags);
          }
        }
        if (url) {
          window.location = url;
        }
      } else {
        this.$router.go(-1);
      }
    },
    toggleListPopup (levelType, level, label) {
      if (this.loading && !this.nodeIdsList) return;
      for (const key in this.popups) {
        if (key === String(levelType)) {
          this.popups[key] = !this.popups[levelType];
          this.popupLevelType = this.popups[key] ? key : undefined;
          this.popupLevel = level;
          this.popupListTitle = label || '';
        } else {
          this.popups[key] = false;
        }
      }
    },
    controlPotNom () {
      this.dataLoading && (this.dataLoading = false);
      if (!this.plantPotNom || !this.umPower || !this.dataLoadDaemon) return;

      this.power = Utils.round(this.totalPower, this.numDec, true);
      this.potNom = this.plantPotNom;
      this.um = this.umPower;

      if (this.totalPower >= 1000) {
        while (this.power.length > 6) {
          const index = this.listUM.indexOf(this.um);
          if (index + 1 === this.listUM.length) break;
          this.potNom = +(this.potNom / 1000);
          this.power = Utils.round((this.power / 1000), this.numDec, true);
          this.um = this.listUM[index + 1];
        }
        this.power = +this.power;
      }
      if (this.totalPower < 1 && this.totalPower > 0) {
        while (this.power < 1) {
          const index = this.listUM.indexOf(this.um);
          if (index - 1 === -1) break;
          this.potNom = +(this.potNom * 1000);
          this.power = Utils.round((this.power * 1000), this.numDec);
          this.um = this.listUM[index - 1];
        }
      }
    }
  },
  mounted () {
    this.alarmsLoadDaemon = new Utils.Daemon((finish) => {
      if (!this.plantNode || !this.plantNode.parameters || !this.plantNode.parameters.plantRefId || !this.plantNode.parameters.plantRefId.val) { finish(); return; }

      this.$store.dispatch('loadActiveAlarms', this.plantNode.parameters.plantRefId.val);
      finish();
    }, Utils.ms('1m'));
    this.urlTags = (this.$route.query.tags && this.$route.query.tags) || '';

    this.alarmsLoadDaemon.start();
  },
  unmounted () {
    if (this.dataLoadDaemon) { this.dataLoadDaemon.stop(); }
    if (this.alarmsLoadDaemon) { this.alarmsLoadDaemon.stop(); }
  }
};

</script>

<style scoped>
.Frame{
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid var(--color-primary);
}

.Frame > .PlantSidebar{
  flex: 0 0 250px;

  display: flex;
  flex-flow: column nowrap;

  background: var(--color-primary-1);
  color: var(--color-primary-text);
}

.Frame > .PlantSidebar > .sidebarBackButton{
flex: 0 0 auto;
background-color: transparent;
border: none;
color: white;
padding: 12px 16px;
border-radius: 16px;
font-size: 16px;
outline: none;
}

.Frame > .PlantSidebar > .sidebarBackButton:hover{
color: var(--color-highlight);
cursor: pointer;
}

.Frame > .PlantSidebar > .sidebar{
flex: 1 1 100%;
}

.Frame > .PlantPage{
  flex: 0 1 100%;
  width: 0;

  display: flex;
  flex-flow: column nowrap;
}

.Frame > .PlantPage > .PlantTabs{
  flex: 0 0 auto;
}

.Frame > .PlantPage > .PlantNavigator{
  flex: 0 1 100%;
  height: 0;
  border-top: 1px solid var(--color-primary);
  border-left: 1px solid var(--color-primary);
}

</style>
